@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Syne:wght@400;500;600;700;800&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --primaryPurple: #646ff0;
  --black-1: #646681;
  --black-2: #585858;
  --bg-1: #f8f8ff;
  --bg-2: #ecedf6;
  --bg-3: #cccdde;
  --gray-1: #eee;
  --gray-2: #dedfe1;
  --black: black;
  --white: white;
}
html {
  font-size: 10px;
}
body {
  font-family: "Poppins", sans-serif;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-1);
}
* {
  font-family: "Poppins", sans-serif;
}
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.heading {
  display: inline-block;
  width: 100%;
  font-family: "Poppins";
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  color: var(--black-1);
}

.wrapper {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

.add_product {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.button {
  display: inline-block;
  height: auto;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1.6rem;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
}
.button--primary {
  background-color: var(--primaryPurple);
  color: var(--white);
}
.button--secondary {
  background-color: var(--bg-3);
  color: var(--black-1);
}

.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_container {
  background-color: var(--bg-2);
  max-width: 500px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
}
.modal_closeButton {
  position: absolute;
  top: -10px;
  right: 0;
  transform: translateY(-100%);
  font-size: 2.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--gray-1);
  color: var(--black-2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease all;
  z-index: -1;
}
.modal_closeButton:hover {
  background-color: #e32525;
  color: white;
}
.modal_form_title {
  color: var(--black-1);
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
.modal_form {
  width: 100%;
}
.modal_form label {
  font-size: 1.6rem;
  color: var(--black-1);
}
.modal_form label input,
.modal_form label select {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;
  padding: 1rem;
  border: none;
  background-color: var(--white);
  font-size: 1.6rem;
}
.modal_button_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}
.secondary_text {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--black-2);
  text-align: center;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: var(--gray-2);
  width: max-content;
  height: auto;
}
.product_items_wrapper {
  margin-top: 2rem;
  background-color: var(--bg-2);
  padding: 4rem 2rem;
  border-radius: 12px;
}
.items_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.item_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  background: var(--white);
  overflow: auto;
}
.item_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
}
.item_text {
  margin-top: 0.5rem;
  text-align: start;
  margin-right: auto;
}
.item_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.item_header h3 {
  font-family: "Poppins";
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--black-1);
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-left: auto;
}
.icon {
  font-size: 2rem;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--gray-1);
  color: var(--black-2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease background-color;
}
.icon:hover {
  background-color: var(--gray-2);
}
